export var strains = [
{"id":"genes_hybrid","name":"Hybrid","description":""},
{"id":"genes_indica","name":"Indica","description":""},
{"id":"genes_sativa","name":"Sativa","description":""},
{"id":"growing_difficult","name":"Difficult","description":""},
{"id":"growing_easy","name":"Easy","description":""},
{"id":"growing_moderate","name":"Moderate","description":""},
{"id":"height_medium","name":"Medium","description":""},
{"id":"height_short","name":"Short","description":""},
{"id":"height_tall","name":"Tall","description":""},
{"id":"medical_add_adhd","name":"ADD\/ADHD","description":""},
{"id":"medical_alzheimers","name":"Alzheimer's","description":""},
{"id":"medical_anorexia","name":"Anorexia","description":""},
{"id":"medical_anxiety","name":"Anxiety","description":""},
{"id":"medical_arthritis","name":"Arthritis","description":""},
{"id":"medical_asthma","name":"Asthma","description":""},
{"id":"medical_bipolar_disorder","name":"Bipolar disorder","description":""},
{"id":"medical_cachexia","name":"Cachexia","description":""},
{"id":"medical_cancer","name":"Cancer","description":""},
{"id":"medical_cramps","name":"Cramps","description":""},
{"id":"medical_crohns_disease","name":"Crohn's disease","description":""},
{"id":"medical_depression","name":"Depression","description":""},
{"id":"medical_epilepsy","name":"Epilepsy","description":""},
{"id":"medical_eye_pressure","name":"Eye pressure","description":""},
{"id":"medical_fatigue","name":"Fatigue","description":""},
{"id":"medical_fibromyalgia","name":"Fibromyalgia","description":""},
{"id":"medical_gastrointestinal_disorder","name":"Gastrointestinal disorder","description":""},
{"id":"medical_glaucoma","name":"Glaucoma","description":""},
{"id":"medical_headaches","name":"Headaches","description":""},
{"id":"medical_hiv_aids","name":"HIV\/AIDS","description":""},
{"id":"medical_hypertension","name":"Hypertension","description":""},
{"id":"medical_inflammation","name":"Inflammation","description":""},
{"id":"medical_insomnia","name":"Insomnia","description":""},
{"id":"medical_lack_of_appetite","name":"Lack of appetite","description":""},
{"id":"medical_migraines","name":"Migraines","description":""},
{"id":"medical_multiple_sclerosis","name":"Multiple sclerosis","description":""},
{"id":"medical_muscle_spasms","name":"Muscle spasms","description":""},
{"id":"medical_muscular_dystrophy","name":"Muscular dystrophy","description":""},
{"id":"medical_nausea","name":"Nausea","description":""},
{"id":"medical_pain","name":"Pain","description":""},
{"id":"medical_parkinsons","name":"Parkinson's","description":""},
{"id":"medical_phantom_limb_pain","name":"Phantom limb pain","description":""},
{"id":"medical_pms","name":"PMS","description":""},
{"id":"medical_ptsd","name":"PTSD","description":""},
{"id":"medical_seizures","name":"Seizures","description":""},
{"id":"medical_spasticity","name":"Spasticity","description":""},
{"id":"medical_spinal_cord_injury","name":"Spinal cord injury","description":""},
{"id":"medical_stress","name":"Stress","description":""},
{"id":"medical_tinnitus","name":"Tinnitus","description":""},
{"id":"medical_tourettes_syndrome","name":"Tourette's syndrome","description":""},
{"id":"negative-effects_anxious","name":"Anxious","description":""},
{"id":"negative-effects_dizzy","name":"Dizzy","description":""},
{"id":"negative-effects_dry_eyes","name":"Dry eyes","description":""},
{"id":"negative-effects_dry_mouth","name":"Dry mouth","description":""},
{"id":"negative-effects_headache","name":"Headache","description":""},
{"id":"negative-effects_paranoid","name":"Paranoid","description":""},
{"id":"positive-effects_aroused","name":"Aroused","description":""},
{"id":"positive-effects_creative","name":"Creative","description":""},
{"id":"positive-effects_energetic","name":"Energetic","description":""},
{"id":"positive-effects_euphoric","name":"Euphoric","description":""},
{"id":"positive-effects_focused","name":"Focused","description":""},
{"id":"positive-effects_giggly","name":"Giggly","description":""},
{"id":"positive-effects_happy","name":"Happy","description":""},
{"id":"positive-effects_hungry","name":"Hungry","description":""},
{"id":"positive-effects_relaxed","name":"Relaxed","description":""},
{"id":"positive-effects_sleepy","name":"Sleepy","description":""},
{"id":"positive-effects_talkative","name":"Talkative","description":""},
{"id":"positive-effects_tingly","name":"Tingly","description":""},
{"id":"positive-effects_uplifted","name":"Uplifted","description":""},
{"id":"tastes_ammonia","name":"Ammonia","description":""},
{"id":"tastes_apple","name":"Discover the Amazing World of Apple Flavors","description":"<p>If you enjoy the taste of apples, you&rsquo;re not alone. Many people savor the crisp, refreshing flavor that makes apples one of the most popular fruits in the world. With so many varieties to choose from, each offering a unique blend of sweetness, tartness, and texture, it&rsquo;s easy to find an apple that suits your preferences perfectly. The good news? Exploring the diverse flavors of apples can add excitement to your meals and snacks.<\/p>\r\n<p>Fans of apples often find that balance is key&mdash;some varieties are delightfully sweet, while others deliver a zesty tartness. Research into apple flavor profiles highlights that the interplay of natural sugars and acids creates the distinctive taste we love. To the delight of apple enthusiasts, there are options ranging from sweet Fuji apples to tangy Granny Smiths, making it easy to pair them with different dishes or enjoy them on their own.<\/p>\r\n<p>When exploring the world of apples, everyone has different tastes, preferences, and culinary needs. That being said, your approach should be to sample a variety of apple types to discover which flavor profile works best for you. Use this collection of apple-inspired ideas to find new ways to enjoy this versatile fruit.<\/p>"},
{"id":"tastes_apricot","name":"Apricot","description":""},
{"id":"tastes_berry","name":"Berry","description":""},
{"id":"tastes_blueberry","name":"Blueberry","description":""},
{"id":"tastes_blue_cheese","name":"Blue cheese","description":""},
{"id":"tastes_butter","name":"Butter","description":""},
{"id":"tastes_cheese","name":"Cheese","description":""},
{"id":"tastes_chemical","name":"Chemical","description":""},
{"id":"tastes_chestnut","name":"Chestnut","description":""},
{"id":"tastes_citrus","name":"Citrus","description":""},
{"id":"tastes_coffee","name":"Coffee","description":""},
{"id":"tastes_diesel","name":"Diesel","description":""},
{"id":"tastes_earthy","name":"Earthy","description":""},
{"id":"tastes_flowery","name":"Flowery","description":""},
{"id":"tastes_grape","name":"Grape","description":""},
{"id":"tastes_grapefruit","name":"Grapefruit","description":""},
{"id":"tastes_honey","name":"Honey","description":""},
{"id":"tastes_lavender","name":"Lavender","description":""},
{"id":"tastes_lemon","name":"Lemon","description":""},
{"id":"tastes_lime","name":"Lime","description":""},
{"id":"tastes_mango","name":"Mango","description":""},
{"id":"tastes_menthol","name":"Menthol","description":""},
{"id":"tastes_mint","name":"Mint","description":""},
{"id":"tastes_nutty","name":"Nutty","description":""},
{"id":"tastes_orange","name":"Orange","description":""},
{"id":"tastes_peach","name":"Peach","description":""},
{"id":"tastes_pear","name":"Pear","description":""},
{"id":"tastes_pepper","name":"Pepper","description":""},
{"id":"tastes_pine","name":"Pine","description":""},
{"id":"tastes_pineapple","name":"Pineapple","description":""},
{"id":"tastes_plum","name":"Plum","description":""},
{"id":"tastes_pungent","name":"Pungent","description":""},
{"id":"tastes_rose","name":"Rose","description":""},
{"id":"tastes_sage","name":"Sage","description":""},
{"id":"tastes_skunk","name":"Skunk","description":""},
{"id":"tastes_spicy_herbal","name":"Spicy herbal","description":""},
{"id":"tastes_strawberry","name":"Strawberry","description":""},
{"id":"tastes_sweet","name":"Sweet","description":""},
{"id":"tastes_tar","name":"Tar","description":""},
{"id":"tastes_tea","name":"Tea","description":""},
{"id":"tastes_tobacco","name":"Tobacco","description":""},
{"id":"tastes_tree_fruit","name":"Tree fruit","description":""},
{"id":"tastes_tropical","name":"Tropical","description":""},
{"id":"tastes_vanilla","name":"Vanilla","description":""},
{"id":"tastes_violet","name":"Violet","description":""},
{"id":"tastes_woody","name":"Woody","description":""},
{"id":"thc_high","name":"High","description":""},
{"id":"thc_low","name":"Low","description":""},
{"id":"thc_medium","name":"Medium","description":""},
{"id":"yield_high","name":"High","description":""},
{"id":"yield_low","name":"Low","description":""},
{"id":"yield_medium","name":"Medium","description":""}
]
