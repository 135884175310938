<template>

    <Strains2
      :category-id="categoryId"
      :category-val="categoryVal"
      :category-name="categoryName"
      :category-description="categoryDescription"
      />

</template>

<script setup> 

  import Strains2 from '~/pages/strains2/index.vue';
  import {strains} from '~/lang/strains/en.js'

  const route = useRoute();

  const categoryId = ref(route.params['category']);
  const categoryVal = ref(route.params['val']);

  const categoryName = computed(() => {
    return strains?.find(item => item.id == categoryId.value + '_' + categoryVal.value)?.name
  })

  const categoryDescription = computed(() => {
    return strains?.find(item => item.id == categoryId.value + '_' + categoryVal.value)?.description
  })

</script>

<style scoped>
 
</style>
